/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  Collapse,
  Form,
  FormInstance,
  Modal,
  Select,
  notification,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { RiDownloadLine } from "react-icons/ri";
// @ts-ignore
// import { useMediaQuery } from "react-responsive";
import { HiOutlineUser, HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { BiErrorCircle } from "react-icons/bi";
import { GoLocation } from "react-icons/go";
// import { RiDownloadLine } from "react-icons/ri";
import {
  MdOutlineKeyboardBackspace,
  MdOutlineNavigateNext,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import { BiTrash } from "react-icons/bi";

import OrderDetailItem from "./OrderDetailItem";
import Dropdown from "./Dropdown";
import Loader from "./Loader";
import {
  getOrderDetail,
  userSelector,
  getStatusList,
  searchProductListing,
  addOrderItem,
  updateOrderItem,
  addOrderAttachment,
  deleteOrderAttachment,
  orderApprove,
  orderHoldStatus,
  cancelOrder,
  getEnumTypeList,
  updateShipmentShippingMethod,
  AwsUserPreference,
} from "../redux/authSlice";
import Table from "./Table";
import Button from "./Button";
import { debounceFn } from "../helper/function";
import Input from "./Input";
import UploadFile from "./UploadFile";
import { BiEdit } from "react-icons/bi";
import UploadDocument from "./UploadDocument";
import moment from "moment";
import axios from "axios";

const OrderDetail = ({
  id,
  handleIdValidity,
}: {
  id: string;
  handleIdValidity: Function;
}) => {
  const history = useHistory();
  const form = React.createRef<FormInstance>();
  const [addForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [importForm] = Form.useForm();
  const [uploadDocumentForm] = Form.useForm();
  const [shippingMethodForm] = Form.useForm();
  // const isMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 450px)",
  // });
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const collapseRef: any = useRef(null);
  const { confirm } = Modal;
  const { activeOrgToggled } = useSelector(userSelector);
  const [orderDetailData, setOrderDetailData] = useState<any>([]);
  const [currStatus, setCurrStatus] = useState<any>("");
  const [orderStatus, setOrderStatus] = useState<any>([]);
  const [returnStatus, setReturnStatus] = useState<any>([]);
  const [shipmentStatus, setShipmentStatus] = useState<any>([]);
  const [customerContact, setCustomerContact] = useState<any>([]);
  const [, setCurrentDate] = useState("");
  const [shipping, setShipping] = useState<any>("");
  const [grandTotal, setGrandTotal] = useState<any>("");
  const [discount, setDiscount] = useState<any>(0);
  const [salesTax, setSalesTax] = useState<any>([]);
  const [subTotal, setSubTotal] = useState<any>([]);
  const [paymentList, setPaymentList] = useState<any>([]);
  const [shipmentDetails, setShipmentDetails] = useState<any>([]);
  // const [shipmentGridDetail, setShipmentGridDetail] = useState<any>([]);
  const [shipmentGridDetail, setShipmentGridDetail] = useState<any>([]);
  const [shipmentItemDetail, setShipmentItemDetail] = useState<any>([]);
  const [customerShippingInfo, setCustomerShippingInfo] = useState<any>({});
  const [orderItems, setOrderItems] = useState<any>([]);
  const [totalQty, setTotalQty] = useState(0);
  // const [isExistingCustomer, setIsExistingCustomer] = useState<any>(false)
  const [, setStatusOnSave] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [showUploadList, setShowUploadList] = useState(false);
  const [isForceLoading, setIsForceLoading] = useState(false);
  const [isFirstTimeRendering, setIsFirstTimeRendering] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [productList, setProductList] = useState<any>([]);
  const [searchableString, setSearchableString] = useState("");
  const [maxPageIndex, setMaxPageIndex] = useState<any>("");
  const [currIndex, setCurrIndex] = useState<any>(1);
  const [hasMoreProds, setHasMoreProds] = useState(true);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [enumType, setEnumType] = useState<any>([]);
  const [isShippingMethodModalOpen, setIsShippingMethodModalOpen] =
    useState(false);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [amazonAws, setAmazonAws] = useState("");

  useEffect(() => {
    fetchData();
  }, [activeOrgToggled]); // eslint-disable-line
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getOrderDetail({
        setOrderDetailData,
        setTotalQty,
        setCurrStatus,
        setStatusOnSave,
        setCustomerContact,
        setGrandTotal,
        setShipping,
        setDiscount,
        setSalesTax,
        setSubTotal,
        setPaymentList,
        id,
        setShipmentDetails,
        setShipmentGridDetail,
        setCustomerShippingInfo,
        setShipmentItemDetail,
        setOrderItems,
        setShippingMethods,
        setIsFirstTimeRendering,
        handleIdValidity,
      })
    );
    await dispatch(
      getStatusList({ setStatus: setOrderStatus, statusTypeId: "OrderHeader" })
    );
    await dispatch(
      getStatusList({ setStatus: setReturnStatus, statusTypeId: "Return" })
    );
    await dispatch(
      getStatusList({ setStatus: setShipmentStatus, statusTypeId: "Shipment" })
    );
    await dispatch(
      getEnumTypeList({
        setEnumType,
        enumTypeId: "ReturnReason",
      })
    );
    await dispatch(AwsUserPreference({ setAmazonAws }));
    // await dispatch(
    //   getCustomersList({ setPageCount, setMaxPageIndex, pageIndex: 0 })
    // );
    // await dispatch(getGeoCountries());
    setIsLoading(false);
    // await dispatch(getStatusListForOrder(setProductStatusData))
    const date = new Date(Date.now() - 2 * 60 * 60 * 1000);
    let formatAMPM: any = date.getHours() / 12;
    if (formatAMPM >= 1) {
      formatAMPM = "PM";
    } else {
      formatAMPM = "AM";
    }
    let minutes: any = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let currDate = date.toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    currDate += `, ${
      date.getHours() % 12 === 0 ? 12 : date.getHours() % 12
    }:${minutes} ${formatAMPM}`;
    setCurrentDate(currDate);
  };
  useEffect(() => {
    if (Object.keys(customerShippingInfo).length) {
      form.current?.setFieldsValue({
        ["name"]: customerShippingInfo?.name, // eslint-disable-line
        ["email"]: customerShippingInfo?.email, // eslint-disable-line
        ["contact"]: customerShippingInfo?.contact, // eslint-disable-line
        ["address1"]: customerShippingInfo?.address1, // eslint-disable-line
        ["address2"]: customerShippingInfo?.address2, // eslint-disable-line
        ["city"]: customerShippingInfo?.city, // eslint-disable-line
        ["postalCode"]: customerShippingInfo?.postalCode, // eslint-disable-line
        ["country"]: customerShippingInfo?.country, // eslint-disable-line
        ["state"]: customerShippingInfo?.state, // eslint-disable-line
      });
    }
  }, [customerShippingInfo]); // eslint-disable-line

  const emailAddress =
    orderDetailData &&
    orderDetailData.order &&
    orderDetailData.order.parts[0].customerEmail;

  function dateConvert(date: any) {
    const data = new Date(date).toDateString().split(" ").slice(1).join(" ");
    return data;
  }
  function timeConvert(time: any) {
    const dis = new Date(time).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return dis;
  }
  // const newData =
  //   orderDetailData.order && orderDetailData.order.orderHistoryAndNotes;
  const orderId =
    " " + orderDetailData &&
    orderDetailData.order &&
    orderDetailData.order.orderId;
  const placedDate =
    "Placed" +
    " " +
    dateConvert(
      orderDetailData &&
        orderDetailData.order &&
        orderDetailData.order.placedDate
    ) +
    " " +
    timeConvert(
      orderDetailData &&
        orderDetailData.order &&
        orderDetailData.order.placedDate
    );

  const getShippedDate = (index: any) => {
    const actualStartDate =
      orderDetailData &&
      orderDetailData?.order &&
      orderDetailData?.order?.shipmentRouteSegment &&
      orderDetailData?.order?.shipmentRouteSegment?.length > 0 &&
      orderDetailData?.order?.shipmentRouteSegment?.[index]?.actualStartDate
        ? orderDetailData?.order?.shipmentRouteSegment?.[index]?.actualStartDate
        : "";
    const shippedDate = actualStartDate
      ? "Shipped " +
        dateConvert(actualStartDate) +
        " " +
        timeConvert(actualStartDate)
      : "";
    return shippedDate;
  };
  // const emailAddress =
  //   orderDetailData &&
  //   orderDetailData.order &&
  //   orderDetailData.order.parts[0].customerEmail;
  // const downloadCsv = async () => {
  //   await dispatch(downloadCsvManifestFile({ orderId: id }));
  // };
  // const shipToHeader: any = (
  //   <h1 className="text-xl font-bold">Edit Ship To Address</h1>
  // );
  function showProductInfo(id: any) {
    const productInfo = orderItems?.find((item: any) => item?.productId === id);
    return productInfo
      ? {
          productName: productInfo.productName,
          sku: productInfo.sku,
          upc: productInfo.upc,
        }
      : null;
  }
  const columns = [
    ...(shipmentGridDetail &&
    shipmentGridDetail.length === 0 &&
    (!orderDetailData?.order?.salesChannelEnumId ||
      orderDetailData?.order?.salesChannelEnumId === "ScWeb" ||
      (orderDetailData?.order?.salesChannelEnumId &&
        orderDetailData?.order?.statusId === "OrderHold"))
      ? [
          {
            title: (
              <div className="flex items-center relative h-5 p-2 leading-3">
                <p className="mr-1"></p>
              </div>
            ),
            dataIndex: "update",
            render: (sku: string, data: any) => {
              return (
                <div className="flex flex-row items-center">
                  <div className="flex flex-row">
                    <h1
                      className="ml-2"
                      onClick={() => {
                        const selectedItem: any = orderItems.find(
                          (item: any) =>
                            item.orderItemSeqId === data?.orderItemSeqId
                        );
                        if (selectedItem) {
                          updateForm?.setFieldsValue({
                            productName: selectedItem?.productName,
                            productId: selectedItem?.productId,
                            orderItemSeqId: selectedItem?.orderItemSeqId,
                            quantity: selectedItem?.quantity,
                          });
                          setIsUpdateModalOpen(true);
                        }
                      }}
                    >
                      <BiEdit className="ml-1 sm:ml-2 text-lg font-semibol text-primary cursor-pointer" />
                    </h1>
                  </div>
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">SKU</p>
        </div>
      ),
      dataIndex: "sku",
      render: (sku: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${data?.productId}`,
            state: { productId: data?.productId },
          }}
        >
          <h1 className="cursor-pointer text-primary underline">{sku}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">UPC</p>
        </div>
      ),
      dataIndex: "upc",
      render: (upc: string, data: any) => (
        <Link
          to={{
            pathname: `/product/${data?.productId}`,
            state: { productId: data?.productId },
          }}
        >
          <h1 className="cursor-pointer text-primary underline">{upc}</h1>
        </Link>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Product Name</p>
        </div>
      ),
      dataIndex: "productName",
      render: (productName: string) => <h1 className="px-2">{productName}</h1>,
    },
    // {
    //   title: (
    //     <div className="flex items-center relative h-5 p-2 leading-3">
    //       <p className="mr-1">Price</p>
    //     </div>
    //   ),
    //   dataIndex: "unitAmount",
    //   render: (unitAmount: number) => (
    //     <h1 className="px-2">$ {unitAmount?.toFixed(2)}</h1>
    //   ),
    // },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">QTY</p>
        </div>
      ),
      dataIndex: "quantity",
      render: (quantity: string) => <h1 className="px-2">{quantity}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Cancelled QTY</p>
        </div>
      ),
      dataIndex: "quantityCancelled",
      render: (quantityCancelled: string) => (
        <h1 className="px-2">{quantityCancelled ?? 0}</h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Short QTY</p>
        </div>
      ),
      dataIndex: "productId",
      render: (productId: any) => {
        let product: any =
          orderDetailData?.order?.overAllocatedOrderItems?.find((item: any) =>
            item.find((subItem: any) => subItem?.productId === productId)
          );
        return (
          <h1 className="px-2">{product?.[0]?.quantityNotAvailable ?? 0}</h1>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Serialized?</p>
        </div>
      ),
      dataIndex: "serialized",
      render: (serialized: any) => {
        return serialized === "Y" && <h1 className="px-2">Serialized</h1>;
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Serial No.</p>
        </div>
      ),
      dataIndex: "serialNumber",
      render: (serialNumber: any) => {
        return (
          serialNumber?.length > 0 &&
          serialNumber?.map((item: any) => {
            return <h1 className="px-2">{item}</h1>;
          })
        );
      },
    },
  ];

  const orderAttachmentColumns = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1"></p>
        </div>
      ),
      dataIndex: "orderContentId",
      render: (orderContentId: string, data: any) => {
        return (
          <div className="flex flex-row items-center">
            <div className="flex flex-row ">
              <h1
                className=""
                onClick={() => {
                  confirm({
                    title: "Are you sure?",
                    icon: "",
                    content: "Do you want to delete this attachment?",
                    okText: "Delete",

                    onOk: async () => {
                      setIsForceLoading(true);
                      await dispatch(
                        deleteOrderAttachment({ orderContentId, fetchData })
                      );
                      setIsForceLoading(false);
                    },
                    onCancel() {
                      // console.log("Cancel");
                    },
                  });
                }}
              >
                <BiTrash className="ml-1 sm:ml-2 text-lg font-semibold text-primary cursor-pointer" />
              </h1>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Name</p>
        </div>
      ),
      dataIndex: "orderContentId",
      render: (orderContentId: string, data: any) => {
        let fileName = data?.contentLocation?.split("/");
        fileName = fileName[fileName?.length - 1];
        return (
          <h1
            className="cursor-pointer underline text-primary"
            onClick={() => {
              const uri = `${process.env.REACT_APP_API_BASE_URL}/apps/oms/Order/OrderDetail/downloadContent?orderContentId=${orderContentId}&orderId=${id}`;
              window.location.href = uri;
            }}
          >
            {fileName}
          </h1>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Description</p>
        </div>
      ),
      dataIndex: "description",
      render: (description: string) => <h1 className="px-2">{description}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Added By</p>
        </div>
      ),
      dataIndex: "userName",
      render: (userName: string) => <h1 className="px-2">{userName}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Date Uploaded</p>
        </div>
      ),
      dataIndex: "contentDate",
      render: (contentDate: string) => (
        <h1 className="px-2">
          {contentDate ? moment(contentDate)?.format("YYYY-MM-DD ") : ""}
        </h1>
      ),
    },
  ];

  const ReturnInfo = [
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Product Name</p>
        </div>
      ),
      dataIndex: "productId",
      render: (productId: any) => {
        return (
          <h1 className="px-2">{showProductInfo(productId)?.productName}</h1>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">SKU</p>
        </div>
      ),
      dataIndex: "productId",
      render: (productId: any) => {
        return (
          <Link
            to={{
              pathname: `/product/${productId}`,
              state: { productId: productId },
            }}
          >
            <h1 className="cursor-pointer text-primary underline px-2">
              {showProductInfo(productId)?.sku}
            </h1>
          </Link>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">UPC</p>
        </div>
      ),
      dataIndex: "productId",
      render: (productId: any) => {
        return (
          <Link
            to={{
              pathname: `/product/${productId}`,
              state: { productId: productId },
            }}
          >
            <h1 className="cursor-pointer text-primary underline px-2">
              {showProductInfo(productId)?.upc}
            </h1>
          </Link>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Status</p>
        </div>
      ),
      dataIndex: "returnStatusId",
      render: (returnStatusId: any) => {
        return (
          <h1
            style={{
              backgroundColor: "#093479",
              outlineColor: "#707070",
              border: "1px solid #707070",
            }}
            className="px-1.5 py-0.5 text-center text-white tracking-wider border font-semibold rounded-md w-max"
          >
            {returnStatus
              ?.find((item: any) => item?.key === returnStatusId)
              ?.value?.toUpperCase()}
          </h1>
        );
      },
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Retuned Quantity</p>
        </div>
      ),
      dataIndex: "returnQuantity",
      render: (returnQuantity: any) => (
        <h1 className="px-2">{returnQuantity}</h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Comment</p>
        </div>
      ),
      dataIndex: "comments",
      render: (comments: any) => <h1 className="px-2">{comments}</h1>,
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Reason</p>
        </div>
      ),
      dataIndex: "reason",
      render: (reason: any) => (
        <h1 className="px-2">
          {enumType?.find((item: any) => item?.key === reason)?.value}
        </h1>
      ),
    },
    {
      title: (
        <div className="flex items-center relative h-5 p-2 leading-3">
          <p className="mr-1">Return Info (ReturnID | RMA | Tracking)</p>
        </div>
      ),
      dataIndex: "returnId",
      render: (returnId: any, data: any) => (
        <h1 className="px-2">
          {returnId}
          {data?.rmaNumber ? ` | ${data?.rmaNumber}` : ""}
          {data?.trackingCode ? ` | ${data?.trackingCode}` : ""}
        </h1>
      ),
    },
  ];

  const downloadLabel = async (url: any, shipmentId: any) => {
    if (shipmentId) {
      setIsLoading(true);
      await axios
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `packing-${shipmentId}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => console.log(error));
      setIsLoading(false);
    }
  };
  return (
    <>
      <div>
        <Loader
          isLoading={isLoading && isFirstTimeRendering}
          styles={{
            overlay: (base: any) => ({
              ...base,
              backgroundColor: "white",
            }),
          }}
        >
          <Modal
            title="Import Incoming shipment"
            visible={isModalOpen}
            footer={null}
            onCancel={() => {
              setShowUploadList(false);
              setIsModalOpen(false);
              setCsvFile(null);
              setIsLoading(false);
            }}
          >
            <Form
              form={importForm}
              onFinish={async (values: any) => {
                if (!csvFile || csvFile.status === "removed") {
                  notification["error"]({
                    message: "Error",
                    description: "Please select a CSV file!",
                  });
                  return;
                }
              }}
            >
              <Form.Item>
                <UploadFile
                  setCsvFile={setCsvFile}
                  isCloudIconVisible={true}
                  showUploadList={showUploadList}
                  setShowUploadList={setShowUploadList}
                />
              </Form.Item>
              <div
                className="flex items-center mb-3 cursor-pointer w-max"
                onClick={() => {
                  window.location.href =
                    process.env.REACT_APP_API_BASE_URL +
                    "/fp-static/downloadIncomingShipmentTemplate";
                }}
              >
                <DownloadOutlined style={{ fontSize: "18px" }} />
                <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                  DOWNLOAD CSV TEMPLATE
                </p>
              </div>
              <Form.Item>
                <div className="w-full mt-5 flex justify-end">
                  <Button
                    widthFull={true}
                    isDisabled={isDisabled}
                    buttonText="Inventory's On It's Way"
                  />
                </div>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Update Item"
            visible={isUpdateModalOpen}
            footer={null}
            onCancel={() => {
              setIsUpdateModalOpen(false);
              updateForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={updateForm}
                onFinish={async (values: any) => {
                  delete values?.productName;
                  setIsForceLoading(true);
                  await dispatch(
                    updateOrderItem({
                      data: values,
                      orderId: id,
                      setIsUpdateModalOpen,
                      fetchData,
                      updateForm,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <div className="sm:flex">
                  <Form.Item name="productId" hidden></Form.Item>
                  <Form.Item name="orderItemSeqId" hidden></Form.Item>
                  <Form.Item
                    name="productName"
                    className="flex-1 w-full"
                    rules={[{ required: false, message: "Select an option!" }]}
                  >
                    <Input
                      title="Product"
                      placeholder="Product"
                      showDot={true}
                      isDisabled
                    />
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Expected Quantity field is required!",
                      },
                      {
                        pattern: /^\d+$/,
                        message: "Please enter a valid number!",
                      },
                    ]}
                  >
                    <Input
                      title="Expected Quantity"
                      placeholder="Expected Quantity"
                      showDot={true}
                    />
                  </Form.Item>
                  {/* <Form.Item name="lotNumber" className="sm:mr-4 flex-1">
                <Input
                  title="Lot Number."
                  placeholder="Lot Number."
                  showDot={true}
                />
              </Form.Item> */}
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Update" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title="Add Item"
            visible={isAddItemModalOpen}
            footer={null}
            onCancel={() => {
              setIsAddItemModalOpen(false);
              addForm?.resetFields();
              setProductList([]);
              setSelectedProduct(null);
            }}
            // width={700}
          >
            <Loader
              isLoading={false}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={addForm}
                onFinish={async (values: any) => {
                  if (!selectedProduct) {
                    notification["error"]({
                      message: "Error",
                      description: "Please select a product.",
                    });
                    return;
                  }

                  const data = {
                    productId: selectedProduct,
                    quantity: values?.quantity,
                    orderId: id,
                  };
                  setIsForceLoading(true);

                  dispatch(
                    addOrderItem({
                      data,
                      orderId: id,
                      setIsAddItemModalOpen,
                      fetchData,
                      setSelectedProduct,
                      addForm,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <div className="sm:flex">
                  <Form.Item
                    name="productId"
                    className="flex-1 w-full"
                    rules={[{ required: true, message: "Select an option" }]}
                  >
                    <div>
                      <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">
                        Product
                      </h1>
                      <Select
                        showSearch
                        value={selectedProduct}
                        placeholder="Search Product"
                        style={{ width: "100%" }}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        className={`border border-gray-400 py-1 w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
                        filterOption={false}
                        onSearch={async (value) => {
                          setSearchableString(() => value);
                          setProductList([]);
                          debounceFn(
                            await dispatch(
                              searchProductListing({
                                productList,
                                setProductList,
                                setMaxPageIndex,
                                queryString: value,
                                currPage: 0,
                                setHasMoreProds,
                              })
                            )
                          );
                        }}
                        onSelect={(value: any) => {
                          setSelectedProduct(value);
                        }}
                        notFoundContent={null}
                        options={productList?.map((item: any) => {
                          const { identifications, productId, name } = item;
                          const sku = identifications?.find(
                            (iden: any) =>
                              iden?.productIdTypeEnumId === "PidtSku"
                          );
                          const upc = identifications?.find(
                            (iden: any) =>
                              iden?.productIdTypeEnumId === "PidtUpca"
                          );
                          const idValue =
                            sku?.idValue || upc?.idValue || productId || "";
                          return {
                            label: `${idValue}${name ? ` | ${name}` : ""}`,
                            value: item?.productId,
                          };
                        })}
                        onPopupScroll={async (e: any) => {
                          const { target } = e;
                          if (
                            (target as any).scrollTop +
                              (target as any).offsetHeight ===
                            (target as any).scrollHeight
                          ) {
                            if (!currIndex || currIndex * 1 === 0) {
                              return;
                            }
                            if (currIndex * 1 > maxPageIndex) {
                              return;
                            }
                            setCurrIndex((prev: any) => prev * 1 + 1);
                            localStorage.setItem(
                              "productListCurrIndex",
                              currIndex
                            );
                            await dispatch(
                              searchProductListing({
                                productList,
                                setProductList,
                                queryString: searchableString,
                                currPage: currIndex,
                                setHasMoreProds,
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </Form.Item>
                </div>
                <div className="sm:flex">
                  <Form.Item
                    name="quantity"
                    className="flex-1 w-full"
                    rules={[
                      {
                        required: true,
                        message: "Quantity field is required",
                      },
                      {
                        pattern: /^\d+$/,
                        message: "Please enter a valid number",
                      },
                    ]}
                  >
                    <Input
                      title="Quantity"
                      placeholder="Quantity"
                      showDot={true}
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button
                      widthFull={true}
                      // isDisabled={isDisabled}
                      buttonText="Add"
                    />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          {/* Update Shipment method */}
          <Modal
            title="Update Shipping method"
            visible={isShippingMethodModalOpen}
            footer={null}
            onCancel={() => {
              setIsShippingMethodModalOpen(false);
              updateForm?.resetFields();
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={shippingMethodForm}
                onFinish={async (values: any) => {
                  const selectedMethod: any = shippingMethods?.find(
                    (item: any) =>
                      values?.ShipmentMethodEnumId ===
                      `${item?.carrierPartyId}${item?.shipmentMethodEnumId}`
                  );
                  setIsForceLoading(true);
                  await dispatch(
                    updateShipmentShippingMethod({
                      shipmentId: values?.shipmentId,
                      carrierPartyId: selectedMethod?.carrierPartyId,
                      shipmentMethodEnumId:
                        selectedMethod?.shipmentMethodEnumId,
                      setIsShippingMethodModalOpen,
                      fetchData,
                    })
                  );
                  setIsForceLoading(false);
                }}
              >
                <Form.Item name="shipmentId" hidden></Form.Item>
                <Form.Item
                  name="ShipmentMethodEnumId"
                  rules={[
                    {
                      required: true,
                      message: "Shipment method field is required",
                    },
                  ]}
                >
                  <Dropdown
                    searchable={true}
                    placeholder="Select One"
                    data={shippingMethods?.map((s: any) => {
                      return {
                        key: `${s?.carrierPartyId}${s?.shipmentMethodEnumId}`,
                        value: s?.description,
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button widthFull={true} buttonText="Update" />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <Modal
            title="Add Attachment"
            visible={isDocumentModalOpen}
            footer={null}
            onCancel={() => {
              setShowUploadList(false);
              setIsDocumentModalOpen(false);
              setFile(null);
              uploadDocumentForm?.resetFields();
              setIsLoading(false);
            }}
          >
            <Loader
              isLoading={isForceLoading}
              styles={{
                overlay: (base: any) => ({
                  ...base,
                  backgroundColor: "",
                }),
              }}
            >
              <Form
                form={uploadDocumentForm}
                onFinish={async (values: any) => {
                  if (!file || file?.status === "removed") {
                    notification["error"]({
                      message: "Error",
                      description: "Please select a file!",
                    });
                    return;
                  }

                  const formData = new FormData();
                  file.forEach((f: any) => {
                    console.log("f.originFileObj", f?.originFileObj);
                    formData.append("contentFile", f?.originFileObj);
                  });
                  formData.append("description", values?.description ?? "");
                  formData.append("orderId", id);
                  setIsForceLoading(true);
                  await dispatch(
                    addOrderAttachment({
                      formData,
                      fetchData,
                      setIsDocumentModalOpen,
                    })
                  );
                  uploadDocumentForm?.resetFields();
                  setIsForceLoading(false);
                  setFile(null);
                  setShowUploadList(false);
                }}
              >
                <Form.Item name="description" className="flex-1 w-full">
                  <Input
                    title="Description"
                    placeholder="Description"
                    showDot={true}
                  />
                </Form.Item>
                <Form.Item>
                  <UploadDocument
                    setFile={setFile}
                    isCloudIconVisible={true}
                    showUploadList={showUploadList}
                    setShowUploadList={setShowUploadList}
                    multiple
                    fileList={file}
                  />
                </Form.Item>

                <Form.Item>
                  <div className="w-full mt-5 flex justify-end">
                    <Button
                      widthFull={true}
                      isDisabled={isDisabled}
                      buttonText="Add"
                    />
                  </div>
                </Form.Item>
              </Form>
            </Loader>
          </Modal>
          <div className="flex flex-col mdlarge:flex-row mb-3">
            <div className="w-full mdlarge:w-8/12 mr-0 mdlarge:mr-3">
              <div>
                <div
                  className="flex flex-row items-center mb-6 cursor-pointer w-max"
                  onClick={() => {
                    history.push("/order/orderListing");
                  }}
                >
                  <MdOutlineKeyboardBackspace
                    style={{ fontSize: "20px" }}
                    className="text-primary"
                  />
                  <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                    BACK TO RESULTS
                  </p>
                </div>
                <div className="bg-white p-5 rounded-lg shadow-sm">
                  <span className="text-base sm:text-lg font-bold mr-5">
                    Order ID # {orderId}
                  </span>
                  <span className="text-gray-500 block ltmob:inline">
                    {placedDate}
                  </span>
                </div>
              </div>
              {customerContact ? (
                <div className="bg-white p-5 pb-6 mb-6 rounded-lg shadow-sm mt-6">
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-base font-bold mr-3 mb-4">
                      Customer Contact
                    </h1>
                    {/* {(statusOnSave === 'OrderOpen' || statusOnSave === 'OrderPlaced') && (
                  <FaPen
                    className='ml-3 mb-2 cursor-pointer'
                    fontSize={16}
                    onClick={() => {
                      setShipToModalVisible(true)
                    }}
                  />
                )} */}
                  </div>
                  {orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.postalAddress?.toName && (
                      <div className="flex flex-row items-center mb-4">
                        <HiOutlineUser size={23} />
                        <h1 className="text-primary text-xs font-bold ml-3">
                          {
                            orderDetailData?.order?.parts[0]?.postalAddress
                              ?.toName
                          }
                        </h1>
                      </div>
                    )}
                  {orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.postalAddress &&
                    orderDetailData?.order?.parts[0]?.postalAddress
                      ?.attnName && (
                      <div className="flex flex-row items-center mb-4">
                        <BiErrorCircle size={23} />
                        <h1 className="ml-2.5">
                          {
                            orderDetailData?.order?.parts[0]?.postalAddress
                              ?.attnName
                          }
                        </h1>
                      </div>
                    )}
                  {orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.postalAddress && (
                      <div className="flex flex-row mb-4">
                        <GoLocation size={22} />
                        <div className="ml-2.5">
                          <h1>
                            {
                              orderDetailData?.order?.parts[0]?.postalAddress
                                ?.address1
                            }{" "}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.address2 && `,`}
                          </h1>
                          <h1>
                            {
                              orderDetailData?.order?.parts[0]?.postalAddress
                                ?.address2
                            }
                          </h1>
                          <h1>
                            {
                              orderDetailData?.order?.parts[0]?.postalAddress
                                ?.city
                            }
                            {orderDetailData?.order?.parts[0]
                              ?.postalAddressStateGeo?.geoCodeAlpha2
                              ? `, ${orderDetailData?.order?.parts[0]?.postalAddressStateGeo?.geoCodeAlpha2}`
                              : ", --"}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.countryGeoId &&
                              `, ${orderDetailData?.order?.parts[0]?.postalAddress?.countryGeoId}`}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.postalCodeExt &&
                              `, ${orderDetailData?.order?.parts[0]?.postalAddress?.postalCodeExt} -`}
                            {orderDetailData?.order?.parts[0]?.postalAddress
                              ?.postalCode &&
                              ` ${orderDetailData?.order?.parts[0]?.postalAddress?.postalCode}`}
                          </h1>
                        </div>
                      </div>
                    )}
                  {orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.telecomNumber && (
                      <div className="flex flex-row items-center mb-4">
                        <HiOutlinePhone size={23} />
                        <h1 className="ml-2.5">
                          {orderDetailData?.order?.parts[0]?.telecomNumber
                            ?.countryCode &&
                            `(${orderDetailData?.order?.parts[0]?.telecomNumber?.countryCode}) `}
                          {orderDetailData?.order?.parts[0]?.telecomNumber
                            ?.areaCode &&
                            `${orderDetailData?.order?.parts[0]?.telecomNumber?.areaCode}-`}
                          {orderDetailData?.order?.parts[0]?.telecomNumber
                            ?.contactNumber &&
                            `${orderDetailData?.order?.parts[0]?.telecomNumber?.contactNumber}`}
                        </h1>
                      </div>
                    )}
                  {orderDetailData?.order?.postalContactEmailAddress && (
                    <div className="flex flex-row items-center mb-4">
                      <HiOutlineMail size={23} />
                      <h1 className="text-primary text-xs font-bold ml-3">
                        {orderDetailData?.order?.postalContactEmailAddress}
                      </h1>
                    </div>
                  )}
                  {!orderDetailData?.order?.postalContactEmailAddress &&
                    orderDetailData?.order?.parts &&
                    orderDetailData?.order?.parts.length > 0 &&
                    orderDetailData?.order?.parts[0]?.customerEmail && (
                      <div className="flex flex-row items-center mb-4">
                        <HiOutlineMail size={23} />
                        <h1 className="text-primary text-xs font-bold ml-3">
                          {orderDetailData?.order?.parts[0]?.customerEmail}
                        </h1>
                      </div>
                    )}
                  {shipmentDetails &&
                  shipmentDetails.partPaymentInfoList &&
                  shipmentDetails.partPaymentInfoList[0] ? (
                    <div>
                      <div className="mt-5">
                        <span className="font-bold mr-3 text-base">
                          Paid Via
                        </span>
                        <span>
                          {customerContact && customerContact.dataSourceId
                            ? customerContact && customerContact.dataSourceId
                            : ""}
                        </span>
                      </div>
                      <div className="mt-3 ml-6">
                        {paymentList &&
                        paymentList[0] &&
                        paymentList[0].value ? (
                          <h1 className="text-xs font-medium">
                            {paymentList && paymentList[0]
                              ? dateConvert(
                                  paymentList &&
                                    paymentList[0] &&
                                    paymentList[0].value
                                ) +
                                " " +
                                timeConvert(
                                  paymentList &&
                                    paymentList[0] &&
                                    paymentList[0].value
                                )
                              : ""}
                          </h1>
                        ) : (
                          ""
                        )}
                        {paymentList &&
                        paymentList[1] &&
                        paymentList[1].value ? (
                          <h1 className="text-xs font-medium">
                            Ref:{" "}
                            {paymentList &&
                              paymentList[1] &&
                              paymentList[1].value}
                          </h1>
                        ) : (
                          ""
                        )}

                        <h1 className="text-xs font-medium">{}</h1>

                        <h1 className="text-xs font-medium">
                          $:
                          {paymentList &&
                            paymentList[2] &&
                            paymentList[2].value}
                        </h1>
                        <h1 className="text-xs font-medium">
                          {paymentList &&
                            paymentList[3] &&
                            paymentList[3].value}
                        </h1>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="w-full mdlarge:w-4/12 ml-0 mdlarge:ml-3 mt-6 mdlarge:mt-0">
              <div className="bg-white p-5 pb-6 rounded-lg shadow-sm mb-6">
                <div className="flex flex-row items-center justify-between mb-2">
                  <h1 className="text-base font-bold mr-3">Order Info</h1>
                  {/* <div
                    className="flex items-center cursor-pointer w-max"
                    // onClick={downloadCsv}
                  >
                    <RiDownloadLine style={{ fontSize: "16px" }} />
                    <p className="ml-1 sm:ml-2 text-xs font-bold text-primary">
                      DOWNLOAD CSV
                    </p>
                  </div> */}
                  <div className="flex">
                    <Button
                      buttonText="Approve"
                      isDisabled={
                        shipmentGridDetail &&
                        shipmentGridDetail?.length === 0 &&
                        ![
                          "OrderCancelled",
                          "OrderCompleted",
                          "OrderApproved",
                        ].includes(orderDetailData?.order?.statusId)
                          ? false
                          : true
                      }
                      onClick={() => {
                        confirm({
                          title: "Are you sure?",
                          icon: "",
                          content: "Do you want to approve this order?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: async () => {
                            await dispatch(
                              orderApprove({
                                orderId: id,
                                fetchData,
                              })
                            );
                          },
                          onCancel() {
                            // console.log("Cancel");
                          },

                          okButtonProps: {
                            className: "rounded-lg text-white font-semibold",
                          },
                          cancelButtonProps: {
                            className: "rounded-lg",
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "#79d15b",
                        borderColor: "#79d15b",
                        color: "#212529",
                        opacity:
                          shipmentGridDetail &&
                          shipmentGridDetail?.length === 0 &&
                          ![
                            "OrderCancelled",
                            "OrderCompleted",
                            "OrderApproved",
                          ].includes(orderDetailData?.order?.statusId)
                            ? 1
                            : 0.5,
                        padding: "10px 15px",
                      }}
                    />
                    <Button
                      buttonText="Hold"
                      isDisabled={
                        shipmentGridDetail &&
                        shipmentGridDetail?.length === 0 &&
                        !["OrderCancelled", "OrderHold"].includes(
                          orderDetailData?.order?.statusId
                        )
                          ? false
                          : true
                      }
                      onClick={() => {
                        confirm({
                          title: "Are you sure?",
                          icon: "",
                          content: "Do you want to hold this order?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: async () => {
                            await dispatch(
                              orderHoldStatus({
                                orderId: id,
                                statusId: "OrderHold",
                                fetchData,
                              })
                            );
                          },
                          onCancel() {
                            // console.log("Cancel");
                          },

                          okButtonProps: {
                            className: "rounded-lg text-white font-semibold",
                          },
                          cancelButtonProps: {
                            className: "rounded-lg",
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "#ffc107",
                        borderColor: "#ffc107",
                        color: "#212529",
                        opacity:
                          shipmentGridDetail &&
                          shipmentGridDetail?.length === 0 &&
                          !["OrderCancelled", "OrderHold"].includes(
                            orderDetailData?.order?.statusId
                          )
                            ? 1
                            : 0.5,
                        padding: "10px 15px",
                      }}
                      classNames="ml-2"
                    />
                    <Button
                      isDisabled={
                        shipmentGridDetail &&
                        shipmentGridDetail?.length === 0 &&
                        orderDetailData?.order?.statusId !== "OrderCancelled"
                          ? false
                          : true
                      }
                      onClick={() => {
                        confirm({
                          title: "Are you sure?",
                          icon: "",
                          content: "Do you want to cancel this order?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: async () => {
                            await dispatch(
                              cancelOrder({ orderId: id, fetchData })
                            );
                          },
                          onCancel() {
                            // console.log("Cancel");
                          },
                          okButtonProps: {
                            className: "rounded-lg text-white font-semibold",
                          },
                          cancelButtonProps: {
                            className: "rounded-lg",
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "#ff6163",
                        borderColor: "#ff6163",
                        color: "#212529",
                        opacity:
                          shipmentGridDetail &&
                          shipmentGridDetail?.length === 0 &&
                          orderDetailData?.order?.statusId !== "OrderCancelled"
                            ? 1
                            : 0.5,
                        padding: "10px 15px",
                      }}
                      buttonText="Cancel"
                      classNames="ml-2"
                    />
                  </div>
                </div>
                <div className="border-b border-gray-300">
                  <div className="flex flex-row gap-16 items-center pb-6 mt-4">
                    <h1
                      className={`text-base font-bold mr-10 ${
                        orderDetailData?.order?.returnInfo && "mb-8"
                      }`}
                    >
                      Status
                    </h1>
                    <div
                      className={`${
                        !orderDetailData?.order?.returnInfo
                          ? "flex flex-col"
                          : "flex flex-col gap-1"
                      }`}
                    >
                      <label
                        style={{
                          backgroundColor: "#093479",
                          outlineColor: "#707070",
                          border: "1px solid #707070",
                        }}
                        htmlFor=""
                        className="px-1.5 py-1 text-base mr-5 text-white tracking-wider border font-semibold rounded-md w-max"
                      >
                        {
                          orderStatus?.find(
                            (item: any) =>
                              item?.key === orderDetailData?.order?.statusId
                          )?.value
                        }
                      </label>
                      {orderDetailData?.order?.returnInfo?.length > 0 && (
                        <div className="flex items-center">
                          <h1
                            onClick={() =>
                              collapseRef?.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              })
                            }
                            style={{
                              backgroundColor: "#F93D6F",
                              outlineColor: "#707070",
                              border: "1px solid #707070",
                              margin: 0,
                            }}
                            className="px-2 py-0.3 text-base mr-5 text-white tracking-wider border font-semibold rounded-md cursor-pointer"
                          >
                            Returned
                          </h1>
                          <p
                            style={{
                              fontSize: "30px",
                              fontWeight: "bold",
                              margin: 0,
                            }}
                          >
                            <MdOutlineNavigateNext />
                          </p>
                        </div>
                      )}
                    </div>
                    {/* {currStatus !== "OrderHeld" &&
                    currStatus !== "OrderCompleted" && (
                      <button
                        className="ml-2 vxsmall:ml-5 px-9 text-xs sm:px-4 sm:text-sm bg-primary hover:shadow-custom-btn hover:text-btn-text-hover focus:outline-none text-white font-semibold py-2 mt-1 rounded-lg"
                        // onClick={async () => {
                        //   setIsLoading(true);
                        //   await dispatch(
                        //     updateStatusInfo({
                        //       statusId: currStatus,
                        //       orderId: id,
                        //       setStatusOnSave,
                        //     })
                        //   );
                        //   setIsLoading(false);
                        // }}
                      >
                        Save
                      </button>
                    )} */}
                  </div>
                  {orderDetailData?.order?.displayId &&
                    orderDetailData?.order?.displayId !== "undefined" && (
                      <h1 className="mb-2">
                        Display ID: {orderDetailData?.order?.displayId}
                      </h1>
                    )}
                  {orderDetailData?.order?.segmentation &&
                    orderDetailData?.order?.segmentation !== "undefined" && (
                      <h1 className="mb-2">
                        Segmentation: {orderDetailData?.order?.segmentation}
                      </h1>
                    )}
                </div>
                <div className="flex flex-row items-center justify-between my-6">
                  <div className="flex flex-row items-center">
                    <h1 className="text-sm font-semibold mr-3">Subtotal</h1>
                    <h1 className="text-gray-500">
                      (
                      {(subTotal && subTotal[1] && subTotal[1].value === 1) ||
                      (subTotal && subTotal[1] && subTotal[1].value === 0)
                        ? `${subTotal && subTotal[1] && subTotal[1].value} item`
                        : `${
                            subTotal && subTotal[1] && subTotal[1].value
                          } items`}
                      )
                    </h1>
                  </div>

                  <h1>${subTotal && subTotal[0] && subTotal[0].value}</h1>
                </div>
                {shipping ? (
                  <div className="flex flex-row items-center justify-between mt-5">
                    <div>
                      <h1 className="text-sm font-semibold mr-3">Shipping</h1>
                      {/* <h1 className='text-gray-500 ml-5'>Next Day Shipping (0.87lbs)</h1> */}
                      <h1 className="text-gray-500 ml-5">
                        {shipping && shipping[1].value}
                      </h1>
                    </div>
                    <h1 className="mt-4">
                      {shipping ? "$" : ""}
                      {shipping && shipping[0].value}
                    </h1>
                  </div>
                ) : (
                  ""
                )}
                {salesTax && salesTax[2] && salesTax[2].value.length > 0 ? (
                  <div className="flex flex-row items-center justify-between mt-5">
                    <div>
                      <h1 className="text-sm font-semibold mr-3">Tax</h1>
                      {salesTax &&
                        salesTax[2] &&
                        salesTax[2].value.map((item: any) => {
                          if (item)
                            return (
                              <h1 className="text-gray-500 ml-5 ">{item}</h1>
                            );
                          return null;
                        })}
                    </div>
                    <div className="flex flex-col mt-6">
                      {salesTax &&
                        salesTax[0] &&
                        salesTax[0].value.map((item: any) => {
                          if (item)
                            return (
                              <h1 className="text-gray-500 ml-5 text-right">
                                ${Number(item).toFixed(2)}
                              </h1>
                            );
                          return null;
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {discount && discount > 0 ? (
                  <div className="flex flex-row items-center justify-between mt-5">
                    <h1 className="text-sm font-semibold mr-3">Discounts</h1>
                    <h1>-${discount.toFixed(2)}</h1>
                  </div>
                ) : null}

                <div className="flex flex-row items-center justify-between mt-5">
                  <h1 className="text-base font-bold">Total</h1>
                  <h1 className="text-base font-bold">
                    ${Number(grandTotal ?? 0)?.toFixed(2)}
                  </h1>
                </div>
              </div>
              <div className="bg-white p-5 pb-6 rounded-lg shadow-sm">
                <div className="mt-2 flex flex-row items-center">
                  <h1 className="font-bold">Total units:</h1>
                  <h1 className="ml-1">{totalQty}</h1>
                </div>
                {orderDetailData?.order?.totalEstimatedAmount ? (
                  <div className="mt-2 flex flex-row items-center">
                    <h1 className="font-bold">Shipping label cost:</h1>
                    <h1 className="ml-1">
                      $
                      {orderDetailData?.order?.totalEstimatedAmount?.toFixed(2)}
                    </h1>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Collapse
            // defaultActiveKey={["1"]}
            className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
            collapsible="header"
            ghost
          >
            <Panel
              header={<h1 className="font-bold text-base">Attachments</h1>}
              key="1"
            >
              <div className="flex justify-between items-center">
                <h1 className="mb-4 font-bold text-base"></h1>
                <Button
                  onClick={() => setIsDocumentModalOpen(true)}
                  buttonText="Add Attachment"
                  classNames="mb-4"
                />
              </div>
              <Table
                rowKey="shipMethod"
                dataSource={orderDetailData?.order?.orderAttachmentList
                  ?.filter((item: any) => !item?.thruDate)
                  ?.sort((a: any, b: any) => {
                    const dateA = moment(a?.contentDate).valueOf();
                    const dateB = moment(b?.contentDate).valueOf();
                    return dateB - dateA;
                  })}
                columns={orderAttachmentColumns}
                isPaginated={false}
              />
            </Panel>
          </Collapse>
          {shipmentGridDetail && shipmentGridDetail.length === 0 && (
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="mb-4">
                  {/* <Button
                  onClick={() => setIsModalOpen(true)}
                  buttonText="Import"
                /> */}
                  <Button
                    onClick={() => setIsAddItemModalOpen(true)}
                    buttonText="Add Item"
                    classNames="ml-2"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
            <h1 className="mb-4 font-bold text-base">Items</h1>
            <Table
              rowKey="shipMethod"
              dataSource={orderItems}
              columns={columns}
              isPaginated={false}
              rowClassName={(record: any) => {
                const isMatched =
                  orderDetailData?.order?.overAllocatedOrderItems?.some(
                    (item: any) =>
                      item.some(
                        (subItem: any) =>
                          subItem?.productId === record?.productId
                      )
                  );
                return isMatched ? "ant-row-color" : "";
              }}
            />
          </div>
          {shipmentGridDetail && shipmentGridDetail.length > 0 ? (
            <div>
              {shipmentGridDetail &&
                shipmentGridDetail.length > 0 &&
                shipmentGridDetail.map((item: any, index: any) => {
                  return (
                    <div className="bg-white rounded-lg shadow-sm p-5 pb-6  mt-6 ">
                      <div className="mb-4">
                        <span className="mr-2 font-bold text-lg">
                          Shipment {index + 1}
                          {": "}
                          {`${
                            shipmentStatus?.find(
                              (data: any) => data?.key === item?.statusId
                            )?.value
                          }`}
                        </span>

                        {/* <span className='text-gray-600'>Items (01-05)</span> */}
                        <span className="text-gray-600 ml-2">
                          <Button
                            buttonText={
                              <div>
                                <DownloadOutlined
                                  className="mr-1"
                                  style={{ fontSize: 20 }}
                                />
                                Packing Slip
                              </div>
                            }
                            paddingX="px-2"
                            paddingY="py-1"
                            onClick={() => {
                              const url = `${process.env.REACT_APP_API_BASE_URL}/fop/apps/PopcAdmin/Shipping/ShipmentInsert?shipmentId=${item?.shipmentId}`;
                              downloadLabel(url, item?.shipmentId);
                            }}
                            defaultColor="bg-blue-800"
                          />
                        </span>

                        <div>
                          <span className="font-bold text-lg">
                            ShipmentId: {item?.shipmentId}
                          </span>
                          <span className="text-gray-500 block ltmob:inline ml-2">
                            {getShippedDate(index)}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between sm:flex-row pl-2 xsmall:pl-3">
                        <div className="mr-0 sm:mr-32 md:mr-0 mb-6 sm:mb-0 flex flex-row">
                          <div className="flex flex-col">
                            <h1 className="flex flex items-center font-bold text-sm mb-2.5">
                              {/* <span className="mr-2">
                                {shipmentDetails &&
                                shipmentDetails.orderPart &&
                                shipmentDetails.orderPart.carrierPartyId
                                  ? shipmentDetails &&
                                    shipmentDetails.orderPart &&
                                    shipmentDetails.orderPart.carrierPartyId
                                  : ""}
                              </span> */}
                              <span className="mr-2">
                                {orderDetailData &&
                                orderDetailData?.order &&
                                orderDetailData?.order?.shipmentRouteSegment &&
                                orderDetailData?.order?.shipmentRouteSegment
                                  ?.length > 0 &&
                                orderDetailData?.order
                                  ?.shipmentRouteSegment?.[0]?.carrierPartyId
                                  ? orderDetailData?.order
                                      ?.shipmentRouteSegment?.[0]
                                      ?.carrierPartyId
                                  : ""}
                              </span>
                              <span>
                                {orderDetailData &&
                                orderDetailData?.order &&
                                orderDetailData?.order?.shipmentRouteSegment &&
                                orderDetailData?.order?.shipmentRouteSegment
                                  ?.length > 0 &&
                                orderDetailData?.order
                                  ?.shipmentRouteSegment?.[0]
                                  ?.shippingShipmentMethod
                                  ? orderDetailData?.order
                                      ?.shipmentRouteSegment?.[0]
                                      ?.shippingShipmentMethod
                                  : ""}
                              </span>
                              {orderDetailData &&
                                orderDetailData?.order &&
                                orderDetailData?.order?.shipmentRouteSegment &&
                                orderDetailData?.order?.shipmentRouteSegment
                                  ?.length > 0 &&
                                orderDetailData?.order?.parts?.some(
                                  (data: any) =>
                                    data?.partShipmentItemSourceList?.some(
                                      (item: any) =>
                                        [
                                          "ShipInput",
                                          "ShipPicked",
                                          "ShipScheduled",
                                        ]?.includes(item?.shipmentStatusId)
                                    )
                                ) && (
                                  <BiEdit
                                    className="ml-1 cursor-pointer"
                                    onClick={() => {
                                      setIsShippingMethodModalOpen(true);
                                      shippingMethodForm?.setFieldValue(
                                        "shipmentId",
                                        item?.shipmentId
                                      );
                                    }}
                                  />
                                )}
                              {/* <span>
                                {shipmentDetails &&
                                shipmentDetails.shipmentMethodEnum &&
                                shipmentDetails.shipmentMethodEnum.description
                                  ? shipmentDetails &&
                                    shipmentDetails.shipmentMethodEnum &&
                                    shipmentDetails.shipmentMethodEnum
                                      .description
                                  : ""}
                              </span> */}
                            </h1>

                            <div className="w-48 ">
                              {item.packages &&
                              item.packages[0] &&
                              item.packages[0].routeSegments[0] &&
                              item.packages[0].routeSegments[0] &&
                              item.packages[0].routeSegments[0].trackingUrl ? (
                                <a
                                  className="underline text-primary font-semibold mr-9 md:mr-4 lg:mr-9 hover:text-text-email"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${
                                    item.packages &&
                                    item.packages[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0]
                                      .trackingUrl
                                  }`}
                                >
                                  {item.packages &&
                                    item.packages[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0]
                                      .trackingCode}
                                </a>
                              ) : (
                                <h1 className="underline text-primary font-semibold mr-9 md:mr-4 lg:mr-9 hover:text-primary hover:underline">
                                  {item.packages &&
                                    item.packages[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0] &&
                                    item.packages[0].routeSegments[0]
                                      .trackingCode}
                                </h1>
                              )}
                            </div>
                          </div>

                          {item.packages &&
                          item.packages[0] &&
                          item.packages[0].routeSegments[0] &&
                          item.packages[0].routeSegments[0] &&
                          item.packages[0].routeSegments[0].labelUrl ? (
                            <div className="mt-7">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="font-bold md:block md:mt-2 lg:mt-0 lg:inline bg-gray-200 text-xs py-0.5 px-2.5 rounded-sm hover:text-text-email"
                                href={`${
                                  item.packages &&
                                  item.packages[0] &&
                                  item.packages[0].routeSegments[0] &&
                                  item.packages[0].routeSegments[0] &&
                                  item.packages[0].routeSegments[0].labelUrl
                                }`}
                              >
                                LABEL MADE
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="">
                          <h1 className="font-bold text-sm mb-2.5">Sent To</h1>
                          <div>
                            <h1 className="font-medium">
                              {shipmentDetails &&
                                shipmentDetails.postalAddress &&
                                shipmentDetails.postalAddress.toName}
                            </h1>
                            <h1 className="font-medium">
                              {shipmentDetails &&
                                shipmentDetails.postalAddress &&
                                shipmentDetails.postalAddress.address1}
                            </h1>
                            <div className="flex flex-row">
                              <h1 className="font-medium">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddress &&
                                  shipmentDetails.postalAddress.city &&
                                  `${shipmentDetails.postalAddress.city} , `}
                              </h1>
                              <h1 className="font-medium ">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddressStateGeo &&
                                  shipmentDetails.postalAddressStateGeo
                                    .geoCodeAlpha2}
                              </h1>
                              <h1 className="font-medium ml-1.5">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddress &&
                                  shipmentDetails.postalAddress.postalCode}
                                {}
                              </h1>
                              <h1 className="font-medium ml-1.5">
                                {shipmentDetails &&
                                  shipmentDetails.postalAddress &&
                                  shipmentDetails.postalAddress.countryGeoId}
                                {"  "}
                              </h1>
                            </div>
                            {customerContact &&
                            customerContact.telecomNumber &&
                            customerContact.telecomNumber.areaCode ? (
                              <h1 className="font-medium mt-4">
                                {" "}
                                {customerContact.telecomNumber ? "+" : ""}
                                {customerContact &&
                                  customerContact.telecomNumber &&
                                  customerContact.telecomNumber
                                    .countryCode}{" "}
                                ({" "}
                                {customerContact &&
                                  customerContact.telecomNumber &&
                                  customerContact.telecomNumber.areaCode}
                                ){" "}
                                {customerContact &&
                                  customerContact.telecomNumber &&
                                  customerContact.telecomNumber.contactNumber}
                              </h1>
                            ) : (
                              ""
                            )}

                            <a
                              href={`mailto:${emailAddress}`}
                              className="underline font-semibold text-primary hover:text-text-email break-all"
                            >
                              {emailAddress}
                            </a>
                          </div>
                        </div>
                      </div>
                      <h1 className="font-bold pb-3 text-sm mb-4 border-b border-gray-300 ">
                        Items
                      </h1>
                      {shipmentItemDetail &&
                        shipmentItemDetail.map((listItem: any, index: any) => {
                          if (listItem.shipmentId === item.shipmentId) {
                            return (
                              <div className="mt-5">
                                <div className="border-b border-gray-300">
                                  <OrderDetailItem
                                    item={listItem}
                                    imageSrc={
                                      listItem.images[0]
                                        ? `${amazonAws}${listItem.images[0]}`
                                        : listItem.images[0]
                                    }
                                    sku={listItem.sku}
                                    name={listItem.productName}
                                    price={listItem.unitAmount}
                                    qty={listItem.quantity}
                                    variant=""
                                  />
                                  {/* <h1 className='font-bold pb-3 border-b border-gray-300 text-sm mb-4'></h1> */}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  );
                })}
            </div>
          ) : (
            <div></div>
          )}
          {orderDetailData?.order?.returnInfo?.length > 0 && (
            <div ref={collapseRef}>
              <Collapse
                // defaultActiveKey={["1"]}
                className="bg-white p-2 rounded-lg shadow-sm mt-5 mb-5"
                collapsible="header"
                ghost
              >
                <Panel
                  id="returnQuantity"
                  header={
                    <h1 className="font-bold text-base">Return Details</h1>
                  }
                  key="2"
                >
                  <Table
                    rowKey="returnQuantity"
                    dataSource={orderDetailData?.order?.returnInfo}
                    columns={ReturnInfo}
                    isPaginated={false}
                  />
                </Panel>
              </Collapse>
            </div>
          )}
        </Loader>
      </div>
    </>
  );
};

export default OrderDetail;
